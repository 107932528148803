import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorNotifier, successNotifier } from "../../util/notifications";
import { useNavigate, useParams } from "react-router-dom";
import useBankList from "../../hooks/useBankList";
import useAccountById from "../../hooks/useAccountById";

export default function AccountFormView() {
  const { id } = useParams(); // Get the account ID from URL params
  const { accountData, isLoading, error,fetchAccountData, updateAccountData } = useAccountById(id);
  const { isLoading: isBankLoading, bankData } = useBankList();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    fetchAccountData(id)
  }, [id])
  // Dynamically set form field values when accountData is available
  useEffect(() => {
    if (accountData) {
      Object.entries(accountData).forEach(([key, value]) => {
        setValue(key, value); // Dynamically set each field value
      });
    }
  }, [accountData, setValue]);

  const errorMessages = () => (
    <span className="flex items-center justify-start text-red-700 m-1">
      Required Field <BsExclamationCircle className="m-1 text-lg" />
    </span>
  );

  const onSubmitHandler = async (formData) => {
    try {
      const updatedAccount = await updateAccountData(id, formData);
      successNotifier("Account updated successfully!");
    } catch (err) {
      errorNotifier(err.message || "Failed to update account.");
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="flex flex-col justify-center w-full">
      <div className="Heading">Update Account</div>

      <div className="flex justify-center w-[100%] p-3">
        <form onSubmit={handleSubmit(onSubmitHandler)} className="forms w-[100%] min-h-[40vh]">

          <div className="InputBox">
            <div>
              <label htmlFor="accountId" className="block mb-1">Account Id</label>
              <input
                type="text"
                {...register("accountId", { required: true })}
                className={errors.accountId ? "errorInput" : ""}
              />
              {errors.accountId && errorMessages()}
            </div>
            <div>
              <label htmlFor="accountName" className="block mb-1">Account Name</label>
              <input
                type="text"
                {...register("accountName", { required: true })}
                className={errors.accountName ? "errorInput" : ""}
              />
              {errors.accountName && errorMessages()}
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="accountType" className="block mb-1">Account Type</label>
              <select
                {...register("accountType", { required: true })}
                className={errors.accountType ? "errorInput" : ""}
              >
                <option value="" disabled>Select Account Type</option>
                <option value="expense">Expense</option>
                <option value="income">Income</option>
                <option value="others">Others</option>
              </select>
              {errors.accountType && errorMessages()}
            </div>

            <div>
              <label htmlFor="remarks" className="block mb-1">Remarks</label>
              <input
                type="text"
                {...register("remarks")}
                className={errors.remarks ? "errorInput" : ""}
              />
              {errors.remarks && errorMessages()}
            </div>
          </div>

          {accountData?.accountType !== "others" && (
            <div className="InputBox">
              <div>
                <label htmlFor="bankId" className="block mb-1">Link Bank Account</label>
                <select
                  {...register("bankId")}
                  className={errors.bankId ? "errorInput" : ""}
                >
                  <option value="">Select Bank</option>
                  {isBankLoading ? (
                    <option>Loading...</option>
                  ) : (
                    bankData?.map((bank) => (
                      <option key={bank._id} value={bank._id}>
                        {bank.bankName} - {bank.accountNo}
                      </option>
                    ))
                  )}
                </select>
                {errors.bankId && errorMessages()}
              </div>
            </div>
          )}

          <div className="Button">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
