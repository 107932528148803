import React, { useEffect, useState } from 'react';
import useEmolyeeForAcc from '../../hooks/useEmolyeeForAcc';
import { ToastContainer } from 'react-toastify';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useForm } from 'react-hook-form'
import { BsExclamationCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { request } from '../../util/fetchAPI';


export default function UserForm() {
    const { isLoading, employeeData, error, refetch: fetchEmployees } = useEmolyeeForAcc()
    const { register, handleSubmit,watch,setValue,reset, formState: { errors } } = useForm({
        defaultValues: {
            isEmployee: "true", // Sets the default value for the "isEmployee" field
        },
    });
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setFilteredData(
            employeeData.filter(emp =>
                emp.employeeId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                emp.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, employeeData]);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleEmpSelect = (employee) => {
        setSelectedEmp(employee);
        setSearchTerm(`${employee.employeeId} - ${employee.employeeName}`);
        setValue("department", (employee.designation?.desigName + " - " + employee.department.departName));
        setValue("NIC", employee.NIC);
    };

    const clearSelectedEmp = () => {
        setSelectedEmp(null);
        setSearchTerm('');
        setValue("department", '');
        setValue("NIC", '');
    };
    const errorMessages = () => {
        return (<span className='flex items-center justify-start text-red-700 m-1'>Required Field <BsExclamationCircle className='m-1 text-lg' /></span>)
    }

    const EmployeeFormHandler = async (datas) => {
        const payload = {
            userId: datas.userId,
            isEmployee: datas?.isEmployee,
            employeeId: selectedEmp?._id,
            userName: datas.userName,
            userEmail: datas.userEmail,
            phoneNum: datas.phoneNum,
            moduleName: datas.moduleName,
            password: datas.password
        }

        try {
            const { data, status } = await request('/user/addUser', 'POST', {
                'Content-Type': 'application/json'
            }, payload)

            if (status === 200) {
                reset()
                successNotifier(data.message)
            } else {
                errorNotifier(data.message)
            }

        } catch (error) {
            console.error("An error occurred while submitting the bank details:", error.message);
        }

    }

    return (
        <div className='FormContainer'>
             <ToastContainer />
            <div className='Heading'>
                Add New User
            </div>

            <div className='FormWraper'>
                <form onSubmit={handleSubmit(EmployeeFormHandler)} className='forms'>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="name">User Id (Login Id)</label>
                            <input type="text" name="name" id="name" {...register("userId", { required: true })} className={errors.UserId ? 'errorInput' : ""} />
                            {errors.UserId && errorMessages()}
                        </div>
                    </div>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="name">Is Employee</label>
                            <select
                                {...register("isEmployee", { required: true })}
                                className={errors.isEmployee ? 'errorInput' : ""}>
                                <option value={false} >No</option>
                                <option value={true}>Yes</option>
                            </select>
                            {errors.isEmployee && errorMessages()}
                        </div>

                        {watch("isEmployee") === "true" &&

                            <div className="relative">
                                <label className='text-base font-semibold'>Select Employee</label>
                                <input
                                    type="text"
                                    placeholder="Search For Doctor..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    readOnly={!!selectedEmp}
                                    className={errors.doctor ? 'errorInput' : ""}
                                />

                                {errors.doctor && errorMessages()}
                                <div className="absolute inset-y-0 my-auto mt-12 mx-6 right-2 flex items-center">
                                    {selectedEmp ? (
                                        <RxCrossCircled
                                            onClick={clearSelectedEmp}
                                            className="cursor-pointer text-red-500 text-xl"
                                        />
                                    ) : (
                                        <span className='text-xs text-indigo-700 font-semibold cursor-pointer p-2 border-2 rounded-full border-indigo-700 hover:bg-black hover:text-white'
                                            onClick={() => setShowDropdown((prev) => !prev)}>SELECT</span>
                                    )}
                                </div>
                                {showDropdown && filteredData.length > 0 && (
                                    <ul className="absolute z-50 min-w-[95%] p-1 mt-1 bg-white border-2 border-gray-400 rounded shadow-2xl">
                                        {filteredData.map((employee, index) => (
                                            <li
                                                key={index}
                                                className="border-b-2 border-b-gray-300 p-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                                                onClick={() => handleEmpSelect(employee)}
                                            >
                                                {employee.employeeId} - {employee.employeeName}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                        }
                        {watch("isEmployee") === "false" &&
                            <div>
                                <label htmlFor="name">User Name</label>
                                <input type="text" {...register("userName", { required: true })} className={errors.userName ? 'errorInput' : ""} />
                                {errors.userName && errorMessages()}
                            </div>}
                    </div>

                    {watch("isEmployee") === "true" &&
                        <div className='InputBox'>
                            <div>
                                <label htmlFor="name">NIC/Passport No. #</label>
                                <input type="text" {...register("NIC", { required: true })} className={errors.NIC ? 'errorInput' : ""} readOnly />
                                {errors.NIC && errorMessages()}
                            </div>
                            <div>
                                <label htmlFor="name">Department & Designation</label>
                                <input type="text" {...register(("department"), { required: true })} className={errors.department ? 'errorInput' : ""} readOnly />
                                {errors.department && errorMessages()}
                            </div>

                        </div>}

                    {watch("isEmployee") === "false" &&
                        <div className='InputBox'>
                            <div>
                                <label htmlFor="name">Email</label>
                                <input type="text" {...register("userEmail", { required: true })} className={errors.userEmail ? 'errorInput' : ""} />
                                {errors.userEmail && errorMessages()}
                            </div>
                            <div>
                                <label htmlFor="name">Phone</label>
                                <input type="text" {...register("Phone", { required: true })} className={errors.Phone ? 'errorInput' : ""} />
                                {errors.Phone && errorMessages()}
                            </div>
                        </div>}

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="password">Module Access</label>
                            <select
                                {...register("moduleName", { required: true })}
                                className={errors.moduleName ? 'errorInput' : ""}>
                                <option value="admin">Admin</option>
                                <option value="finance">Finance</option>
                                <option value="client">Client</option>
                                {watch("isEmployee") === "true" &&
                                    <option value="doctor">Doctor</option>}
                            </select>
                            {errors.moduleName && errorMessages()}
                        </div>
                        <div>
                            <label htmlFor="password">Password</label>
                            <div className='relative'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    {...register("password", { required: true })}
                                    className={errors.password ? 'errorInput' : ""}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute right-10 top-8 transform -translate-y-1/2 text-gray-500"
                                >
                                    {showPassword ? "Hide" : "Show"}
                                </button>
                            </div>
                            {errors.password && errorMessages()}
                        </div>
                    </div>

                    <div className='Button'>
                        <button>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
