import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Empolyee from '../EmpolyeeForm/EmpolyeeForm';
import EmpList from '../EmpList/EmpList';
import DoctorList from '../DoctorList/DoctorList';
import UserForm from '../UserForm/UserForm';
import UserList from '../UserList/UserList';
import DepartDeisg from '../DepartDeisg/DepartDeisg';
import IdForm from '../IdForm/IdForm';
import BanksForm from '../BankForm/BanksForm';
import BankList from '../BankList/BankList';
import AccountForm from '../AccountForm/AccountForm';
import AccountsDlt from '../AccountsDlt/AccountsDlt';
import ExpenseList from '../ExpenseList/ExpenseList';
import IncomeList from '../IncomeList/IncomeList';
import ReceiptList from '../ReceiptList/ReceiptList';
import CompanyProfile from '../CompanyProfile/CompanyProfile';
import VoucherForm from '../VoucherForm/VoucherForm';  // Add VoucherForm
import VoucherList from '../VoucherList/VoucherList';  // Add VoucherList
import BankFormView from '../BankFormView/BankFormView';
import AccountFormView from '../AccountFormView/AccountFormView';
import UnverifiedReceiptList from '../UnverifiedReceiptList/UnverifiedReceiptList';
import ReceiptView from '../ReceiptView/ReceiptView';
import SupplierForm from '../SupplierForm/SupplierForm';
import ChangePwd from '../ChangePwd/ChangePwd';

export default function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/changePwd' element={<ChangePwd/>}/>
        <Route path='/addEmpolyee' element={<Empolyee />} />
        <Route path='/empolyeeList' element={<EmpList />} />
        <Route path='/DepartDeisg' element={<DepartDeisg />} />
        <Route path='/doctorList' element={<DoctorList />} />
        <Route path='/addUser' element={<UserForm />} />
        <Route path='/userList' element={<UserList />} />
        <Route path='/addIds' element={<IdForm />} />
        <Route path='/addBank' element={<BanksForm />} />
        <Route path='/bankList' element={<BankList />} />
        <Route path='/bankView/:id' element={<BankFormView />} />
        <Route path='/addAccount' element={<AccountForm />} />
        <Route path='/accountDetail' element={<AccountsDlt />} />
        <Route path='/accountView/:id' element={<AccountFormView />} />
        <Route path='/expenseList' element={<ExpenseList />} />
        <Route path='/incomeList' element={<IncomeList />} />
        <Route path='/unverifiedReceiptList' element={<UnverifiedReceiptList />} />
        <Route path='/receiptList' element={<ReceiptList />} />
        <Route path='/receiptView/:id' element={<ReceiptView />} />
        <Route path='/addSupplier' element={<SupplierForm />} />
        <Route path='/addVoucher' element={<VoucherForm />} />
        <Route path='/voucherList' element={<VoucherList />} />
        <Route path='/companyProfile' element={<CompanyProfile />} />
      </Routes>
    </div>
  );
}
