import React, { useEffect, useState } from 'react'
import CompanyProfileForm from './CompanyProfileForm'
import CompanyProfileView from './CompanyProfileView';
import useCompany from '../../hooks/useCompany';

export default function CompanyProfile() {

  const { isLoading, isCompanyExit, error, fetchProfile } = useCompany()


  return (
    <div>
      {isCompanyExit ?
      <CompanyProfileView CompanyProfile={isCompanyExit}/>
      :
      <CompanyProfileForm/>
      }
    </div>
  )
}
