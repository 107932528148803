import React from "react";
import { useForm } from "react-hook-form";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { request } from "../../util/fetchAPI"; // Assume this utility function is used for API requests

export default function CompanyProfileView({ CompanyProfile }) {
  const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm({
    defaultValues: {
      companyName: CompanyProfile?.companyName || "",
      ownerName: CompanyProfile?.ownerName || "",
      contact1: CompanyProfile?.contact1 || "",
      contact2: CompanyProfile?.contact2 || "",
      email: CompanyProfile?.email || "",
      website: CompanyProfile?.website || "",
      address: CompanyProfile?.address || "",
      NTN: CompanyProfile?.NTN || "",
      STN: CompanyProfile?.STN || "",
    },
  });

  const editCompanyProfile = async (payload) => {
    try {
      const { data, status } = await request(`/company/updateCompanyProfile/${CompanyProfile._id}`, "PUT", {
        "Content-Type": "application/json",
      }, payload);

      if (status === 200 || status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred while updating the company profile:", error.message);
    }
  };

  const deleteCompany = async () => {
    try {
      const { data, status } = await request(`/company/deleteCompanyProfile/${CompanyProfile._id}`, "DELETE", {
        "Content-Type": "application/json",
      });

      if (status === 200 || status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred while deleting the company profile:", error.message);
    }
  };

  const onSubmit = (data) => {
    editCompanyProfile(data);
  };

  return (
    <div className="FormContainer">
      <div className="Heading">Company Profile</div>
      <div className="h-50 w-50">
        <img
          src={`https://api.kinifitclinic.online/images/${CompanyProfile.logo}`}
          alt=""
          className="h-38 w-96"
        />
      </div>
      <div className="FormWrapper">
        <form className="forms" onSubmit={handleSubmit(onSubmit)}>
          {/* Company Name and Owner Name */}
          <div className="InputBox">
            <div>
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                {...register("companyName")}
              />
            </div>
            <div>
              <label htmlFor="ownerName">Owner Name</label>
              <input
                type="text"
                id="ownerName"
                {...register("ownerName")}
              />
            </div>
          </div>

          {/* Contact 1, Contact 2, and Email */}
          <div className="InputBox">
            <div>
              <label htmlFor="contact1">Contact 1</label>
              <input
                type="text"
                id="contact1"
                {...register("contact1")}
              />
            </div>
            <div>
              <label htmlFor="contact2">Contact 2</label>
              <input
                type="text"
                id="contact2"
                {...register("contact2")}
              />
            </div>
          </div>

          {/* Website and Address */}
          <div className="InputBox">
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                {...register("email")}
              />
            </div>
            <div>
              <label htmlFor="website">Website</label>
              <input
                type="text"
                id="website"
                {...register("website")}
              />
            </div>
          </div>

          {/* Address */}
          <div className="InputBox">
            <div>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                {...register("address")}
              />
            </div>
          </div>

          {/* NTN and STN */}
          <div className="InputBox">
            <div>
              <label htmlFor="ntn">NTN</label>
              <input
                type="text"
                id="ntn"
                {...register("NTN")}
              />
            </div>
            <div>
              <label htmlFor="stn">STN</label>
              <input
                type="text"
                id="stn"
                {...register("STN")}
              />
            </div>
          </div>

          {/* Action buttons */}
          <div className="flex mt-10 p-5 gap-5">
            <button
              type="submit"
              className="bg-green-600 p-2 text-white rounded-md min-w-52"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>Please wait <Spin className="text-white font-bold ml-2" indicator={<LoadingOutlined spin />} /></>
              ) : (
                "Update"
              )}
            </button>

            <button
              type="button"
              className="bg-red-600 p-2 text-white rounded-md min-w-52"
              onClick={deleteCompany}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>Please wait <Spin className="text-white font-bold ml-2" indicator={<LoadingOutlined spin />} /></>
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
