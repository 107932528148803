import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { BsExclamationCircle } from "react-icons/bs";
import useBankById from "../../hooks/useBankById";
import "./BankFormView.css"
import TransectionTable from "../TransectionTable/TransectionTable";

export default function BankFormView() {
  const { id } = useParams(); // Get bank ID from URL params
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const { isLoading, bankData, error, fetchBankData, updateBankData } = useBankById();
  console.log(bankData)
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  // Fetch bank data when component mounts
  useEffect(() => {
    fetchBankData(id);
  }, [id]);

  // Populate form fields when data is available
  useEffect(() => {
    if (bankData) {
      Object.entries(bankData).forEach(([key, value]) => {
        setValue(key, value); // Dynamically set form field values
      });
    }
  }, [bankData, setValue]);

  // Submit updated bank details
  const submitBankDetails = async (formData) => {
    await updateBankData(id, formData); // Update bank details
  };

  const errorMessages = () => (
    <span className="flex items-center text-red-700 m-1">
      Required Field <BsExclamationCircle className="ml-1 text-lg" />
    </span>
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="FormContainer">
      <div className="Heading">Update Bank Details</div>

      <div className="FormWrapper">
        <form onSubmit={handleSubmit(submitBankDetails)} className="forms">
          {/* Bank Name and Branch Code */}
          <div className="InputBox">
            <div>
              <label htmlFor="bankId">Bank Id</label>
              <input
                type="text"
                id="bankId"
                {...register("bankId")}
                className="disabledInput"
                disabled // Bank ID should not be editable
              />
            </div>
            <div>
              <label htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                id="bankName"
                {...register("bankName", { required: true })}
                className={errors.bankName ? "errorInput" : ""}
              />
              {errors.bankName && errorMessages()}
            </div>
          </div>

          {/* Bank Address and Account Number */}
          <div className="InputBox">
            <div>
              <label htmlFor="branchCode">Branch Code</label>
              <input
                type="text"
                id="branchCode"
                {...register("branchCode", { required: true })}
                className={errors.branchCode ? "errorInput" : ""}
              />
              {errors.branchCode && errorMessages()}
            </div>
            <div>
              <label htmlFor="bankAddress">Bank Address</label>
              <input
                type="text"
                id="bankAddress"
                {...register("bankAddress", { required: true })}
                className={errors.bankAddress ? "errorInput" : ""}
              />
              {errors.bankAddress && errorMessages()}
            </div>
          </div>

          {/* Account Number and Previous Balance */}
          <div className="InputBox">
            <div>
              <label htmlFor="accountNo">Account Number/IBAN</label>
              <input
                type="text"
                id="accountNo"
                {...register("accountNo", { required: true })}
                className={errors.accountNo ? "errorInput" : ""}
              />
              {errors.accountNo && errorMessages()}
            </div>
            <div>
              <label htmlFor="preBalance">Current Balance</label>
              <input
                type="number"
                id="preBalance"
                {...register("preBalance", { required: true })}
                className={errors.preBalance ? "errorInput" : ""}
                readOnly
              />
              {errors.preBalance && errorMessages()}
            </div>
          </div>

          {/* Submit Button */}
          <div className="Button">
            <button type="submit">Update</button>
          </div>
        </form>
      </div>
            <div className="tabs-container">
        {/* Tab Links */}
        <div className="tabs">

          <button
            className={`tab-link ${activeTab === "tab1" ? "active" : ""}`}
            onClick={() => handleTabClick("tab1")}
          >
            Bank Transections
          </button>
        </div>

        {/* Tab Content */}
        <div className="tabs-content">
          {activeTab === "tab1" && <div className="tab-pane active">
            <TransectionTable TransDtl={bankData.TransDtl}/>
          </div>}
        </div>
      </div>
    </div>
  );
}
