import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'antd';
import { BsExclamationCircle } from 'react-icons/bs';
import useBankList from '../../hooks/useBankList';
import useVerfyReceipt from '../../hooks/useVerfyReceipt';

export default function AccountSelector({ receiptId, isModalOpen, handleCancel, onVerificationComplete }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { verifyReceipts } = useVerfyReceipt();
    const { isLoading: isBankLoading, bankData } = useBankList();

    const errorMessages = () => (
      <span className="flex items-center justify-start text-red-700 m-1">
        Required Field <BsExclamationCircle className="m-1 text-lg" />
      </span>
    );

    const handleOk = async (data) => {
      try {
        const { bankId } = data;
        await verifyReceipts(receiptId, bankId); // Verifies receipt
        handleCancel(); // Closes modal
        onVerificationComplete(); // Notify parent to reload
      } catch (err) {
        console.error('Error verifying receipt:', err);
      }
    };

    return (
      <Modal
        title="Receipt Verification"
        open={isModalOpen}
        onOk={handleSubmit(handleOk)} // Use handleSubmit to validate and pass form data
        onCancel={handleCancel}
      >
        <div className="w-full">
          <div>
            <label htmlFor="bankId" className="block mb-1">
              Account List
            </label>
            <select
              {...register("bankId", { required: true })}
              className="w-full border-2 border-gray-200 p-2"
              defaultValue=""
            >
              <option value="" disabled>
                Select Debit/Credit Account
              </option>
              {isBankLoading ? (
                <option>Loading...</option>
              ) : (
                bankData?.map((bank) => (
                  <option key={bank._id} value={bank._id}>
                    {bank.bankName} - {bank.accountNo}
                  </option>
                ))
              )}
            </select>
            {errors.bankId && errorMessages()}
          </div>
        </div>
      </Modal>
    );
  }
