import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useReceipts from '../../hooks/useReceipt';
import { ToastContainer } from 'react-toastify';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdVerifiedUser, MdPrint } from "react-icons/md";
import AccountSelector from '../Modals/AccountSelector';
import "./ReceiptList.css";

export default function ReceiptList() {
  const { register, watch } = useForm({
    defaultValues: {
      dateFrom: new Date().toISOString().split('T')[0],
      dateTo: new Date().toISOString().split('T')[0],
    },
  });
  const { isLoading, receiptData, fetchReceipts } = useReceipts();
  const [receiptId, setReceiptData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const onVerificationComplete = () => {
    // Refetch receipts after a successful verification
    const dateFrom = watch("dateFrom");
    const dateTo = watch("dateTo");
    fetchReceipts(dateFrom, dateTo);
  };

  // Filter receipts by search term
  useEffect(() => {
    const filterData = receiptData?.filter((data) =>
      data.receiptNo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filterData);
  }, [searchTerm, receiptData]);

  const handleSearch = (event) => setSearchTerm(event.target.value);

  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");

  useEffect(() => {
    if (dateFrom && dateTo) {
      fetchReceipts(dateFrom, dateTo); // Fetch receipts within the selected date range
    }
  }, [dateFrom, dateTo]);

  return (
    <section className="bg-gray-50 w-full h-screen overflow-auto dark:bg-gray-900 p-3 sm:p-5">
      <ToastContainer />
      <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight">Receipt List</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            {isLoading ? (
              <div className="loaderContainer">
                <span className="loader"></span>
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">#</th>
                    <th scope="col" className="px-4 py-3">Receipt Id</th>
                    <th scope="col" className="px-4 py-3">Source</th>
                    <th scope="col" className="px-4 py-3">Reference</th>
                    <th scope="col" className="px-4 py-3">Type</th>
                    <th scope="col" className="px-4 py-3">Amount</th>
                    <th scope="col" className="px-4 py-3">Status</th>
                    <th scope="col" className="px-4 py-3">Dated</th>
                    <th scope="col" className="sr-only"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.map((data, i) => (
                    <tr key={data._id} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3">{i + 1}</td>
                      <td className="px-4 py-3 font-medium text-gray-900 dark:text-white">{data.receiptNo}</td>
                      <td className="px-4 py-3">{data.receiptSource}</td>
                      <td className="px-4 py-3">{data.visitId?.prefix}-{data.visitId?.visitNo}</td>
                      <td className={`px-4 py-3 ${data.receiptType === 'debit' ? 'text-green-800' : 'text-red-800'} font-bold`}>
                        {data.receiptType}
                      </td>
                      <td className="px-4 py-3">{data.amount}.00</td>
                      <td className={`px-4 py-3 font-bold ${data.bankId === null ? 'text-red-700' : 'text-lime-700'} uppercase`}>
                        {data.bankId === null ? "Unverified" : "Verified"}
                      </td>
                      <td className="px-4 py-3">{new Date(data.date).toDateString()}</td>
                      <td className="px-4 py-3 flex items-center justify-end gap-2 cursor-pointer">
                        <FaEye className="text-xl" onClick={() => navigate(`/receiptView/${data._id}`)} />
                        {data.bankId === null &&
                          <MdVerifiedUser className="text-xl" onClick={() => { showModal(); setReceiptData(data._id); }} />}
                        <MdPrint className="text-xl" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <AccountSelector
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        receiptId={receiptId}
        onVerificationComplete={onVerificationComplete} // Callback to refetch data
      />
    </section>
  );
}
