import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useDispatch } from "react-redux";
import { request } from "../../util/fetchAPI";
import { login } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Logo from "../../assets/Logo.jpg";
import "./Login.css";

export default function Login() {
  const {
    register, // Registers inputs
    handleSubmit, // Handles form submission
    formState: { errors, isSubmitting }, // Tracks form state
  } = useForm({
    defaultValue:{
      moduleName:"admin"
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    try {
      const data = await request(
     "/auth/UserLogin",
        "POST",
        { "Content-Type": "application/json" },
        {...formData, moduleName:"admin"}
      );

      if (data.status !== 200) {
        errorNotifier(data.data.message);
      } else {
        dispatch(login(data.data));
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      errorNotifier("Un-Expected Error");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="min-h-[100vh] w-[100%] flex justify-center items-center bg-slate-50">

        <div className="flex min-h-[65vh] w-[70%] m-2 justify-center items-center px-6 py-12 lg:px-8 border-2 border-gray-500 rounded-lg Form">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mx-auto w-fit p-2 bg-white text-center text-2xl font-bold leading-9 tracking-tight text-black">
              Login To Your Account
            </h2>

            <img
              alt="Your Company"
              src={Logo}
              className="mx-auto h-50vh w-full"
            />
            <div className="mb-5">
              <p className="mx-auto w-fit text-center text-lg text-black">
                For any query feel free to contact us.
              </p>
              <p className="mx-auto w-fit text-center text-lg text-black">
                Email: m.arslan.soft.solution@gmail.com
              </p>
              <p className="mx-auto w-fit text-center text-lg text-black">
                Contact: 0317-1505390
              </p>
            </div>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              onSubmit={handleSubmit(onSubmit)} // Wrap the submit handler
              className="space-y-6"
            >
              <div>
                <label
                  htmlFor="UserId"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  User Id
                </label>
                <div className="mt-2">
                  <input
                    id="UserId"
                    type="text"
                    {...register("userId", { required: "User Id is required" })}
                    className={`block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.UserId ? "border-red-500" : ""
                    }`}
                  />
                  {errors.UserId && (
                    <p className="text-red-500 text-sm">
                      {errors.UserId.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    type="password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                    className={`block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.password ? "border-red-500" : ""
                    }`}
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full">
                <button
                  className="bg-indigo-900 w-full text-white rounded-sm p-2"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      Please wait{" "}
                      <Spin
                        className="text-white font-bold ml-2"
                        indicator={<LoadingOutlined spin />}
                      />
                    </>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
