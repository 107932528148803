import React, { useEffect, useState } from "react";
import useDepart from "../../hooks/useDepart";
import useDesig from "../../hooks/useDesig";
import useDocumentId from "../../hooks/useDocumentId";
import { ToastContainer } from 'react-toastify';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { request } from "../../util/fetchAPI";

export default function EmployeeForm() {
  const { register, handleSubmit,watch,setValue,reset, formState: { errors } } = useForm();
  const { isLoading: DepartLoading, departData, error: DepartError } = useDepart();
  const { isLoading: DesigLoading, desigData, error: DesigError } = useDesig();
  const {docIdData, fetchdocIdData } = useDocumentId({});
  const [deptSearchTerm, setDeptSearchTerm] = useState("");
  const [desigSearchTerm, setDesigSearchTerm] = useState("");
  const [filteredDeptData, setFilteredDeptData] = useState([]);
  const [filteredDesigData, setFilteredDesigData] = useState([]);
  const [selectedDept, setSelectedDept] = useState(null);
  const [selectedDesig, setSelectedDesig] = useState(null);
  const [showDepDropdown, setShowDepDropdown] = useState(false);
  const [showDesDropdown, setShowDesDropdown] = useState(false);
  const errorMessages = () => (
    <span className="flex items-center text-red-700 m-1">
      Required Field <BsExclamationCircle className="ml-1 text-lg" />
    </span>
  );

  // Filter department data based on search term
  useEffect(() => {
    if (departData) {
      setFilteredDeptData(
        departData.filter(
          (data) =>
            data.departId.toLowerCase().includes(deptSearchTerm.toLowerCase()) ||
            data.departName.toLowerCase().includes(deptSearchTerm.toLowerCase())
        )
      );
    }
  }, [deptSearchTerm, departData]);

  // Filter designation data based on search term
  useEffect(() => {
    if (desigData) {
      setFilteredDesigData(
        desigData.filter(
          (data) =>
            data.desigId.toLowerCase().includes(desigSearchTerm.toLowerCase()) ||
            data.desigName.toLowerCase().includes(desigSearchTerm.toLowerCase())
        )
      );
    }
  }, [desigSearchTerm, desigData]);

  // Form submission handler
  const EmployeeFormHandler = async (data) => {
    const payload = {
      ...data,
        accountId:docIdData.accountId,
        prefix:docIdData.prefix,
        employeeId:docIdData.currentId,
        department:selectedDept._id,
        designation:selectedDesig._id
    }
    try {
      const { data, status } = await request("/employee/addEmployee", "POST", {
        "Content-Type": "application/json",
      }, {...payload});

      if(status === 200){
        reset()
        successNotifier(data.message)
    }else{
        errorNotifier(data.message)
    }

    } catch (error) {
      console.error("An error occurred while submitting the bank details:", error.message);
    }
  };

  const handleDeptSelect = (department) => {
    setSelectedDept(department);
    setDeptSearchTerm(`${department.departId} - ${department.departName}`);
    setValue("department", department.departName);
  };

  const clearSelectedDept = () => {
    setSelectedDept(null);
    setDeptSearchTerm("");
    setValue("department", "");
  };

  const handleDesigSelect = (designation) => {
    setSelectedDesig(designation);
    setDesigSearchTerm(`${designation.desigId} - ${designation.desigName}`);
    setValue("designation", designation.desigName);
  };

  const clearSelectedDesig = () => {
    setSelectedDesig(null);
    setDesigSearchTerm("");
    setValue("designation", "");
  };

  useEffect(() => {
    fetchdocIdData("employee");

  }, []);

  useEffect(() => {
    if (docIdData?.prefix && docIdData?.currentId) {
      setValue("employeeId", `${docIdData.prefix}`+  -  + `${docIdData.currentId}`);
    }
  }, [docIdData, setValue]);

  return (
    <div className="FormContainer">
       <ToastContainer />
      <div className="Heading">Add New Employee</div>

      <div className="FormWrapper">
        <form onSubmit={handleSubmit(EmployeeFormHandler)} className="forms">
          {/* Employee ID */}
          <div className="InputBox">
            <div>
              <label htmlFor="employeeId">Employee ID</label>
              <input
                type="text"
                id="employeeId"
                {...register("employeeId", { required: true })}
                className={errors.employeeId ? "errorInput" : ""}
              />
              {errors.employeeId && errorMessages()}
            </div>
          </div>

          {/* Employee Name and NIC */}
          <div className="InputBox">
            <div>
              <label htmlFor="employeeName">Employee Name</label>
              <input
                type="text"
                id="employeeName"
                {...register("employeeName", { required: true })}
                className={errors.employeeName ? "errorInput" : ""}
              />
              {errors.employeeName && errorMessages()}
            </div>
            <div>
              <label htmlFor="NIC">NIC/Passport No.</label>
              <input
                type="text"
                id="NIC"
                {...register("NIC", { required: true })}
                className={errors.NIC ? "errorInput" : ""}
              />
              {errors.NIC && errorMessages()}
            </div>
          </div>

          {/* Gender and Age */}
          <div className="InputBox">
            <div>
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                {...register("gender", { required: true })}
                className={errors.gender ? "errorInput" : ""}
              >
                <option value="" hidden>
                  Choose...
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              {errors.gender && errorMessages()}
            </div>
            <div>
              <label htmlFor="age">Age</label>
              <input
                type="text"
                id="age"
                {...register("age", { required: true })}
                className={errors.age ? "errorInput" : ""}
              />
              {errors.age && errorMessages()}
            </div>
          </div>


          <div className='InputBox'>
            <div>
              <label >Joining Date</label>
              <input type="date"  {...register("joiningDate", { required: true })}
                className={errors.joiningDate ? 'errorInput' : ""} />
              {errors.joiningDate && errorMessages()}
            </div>

            <div>
              <label >Education</label>
              <input type="text"  {...register("education", { required: true })}
                className={errors.education ? 'errorInput' : ""} />
              {errors.education && errorMessages()}
            </div>

          </div>

          <div className='InputBox'>

            <div>
              <label >Previous Experence</label>
              <input type="text"  {...register("preExp", { required: true })}
                className={errors.preExp ? 'errorInput' : ""} />
              {errors.preExp && errorMessages()}
            </div>
            <div>
              <label >Organization Name</label>
              <input type="text"  {...register("orgName", { required: true })}
                className={errors.orgName ? 'errorInput' : ""} />
              {errors.orgName && errorMessages()}
            </div>


          </div>

          {/* Department and Designation */}
          <div className="InputBox">
            <div className="relative">
              <label>Department</label>
              <input
                type="text"
                placeholder="Search Designation..."
                value={deptSearchTerm}
                onChange={(e) => setDeptSearchTerm(e.target.value)}
                readOnly={!!selectedDept}
                className={errors.department ? "errorInput" : ""}
              />
              {errors.department && errorMessages()}
              <div className="absolute inset-y-0 my-auto mt-12 mx-6 right-2 flex items-center">
                {selectedDept ? (
                  <RxCrossCircled
                    onClick={clearSelectedDept}
                    className="cursor-pointer text-red-500 text-xl"
                  />
                ) : (
                  <button
                    type="button"
                    onClick={() => setShowDepDropdown((prev) => !prev)}
                    className='text-xs text-indigo-700 font-semibold cursor-pointer p-2 border-2 rounded-full border-indigo-700 hover:bg-black hover:text-white'
                  >
                    Select
                  </button>
                )}
              </div>

              {filteredDeptData.length > 0 && showDepDropdown && (
                <ul className="absolute z-50 min-w-[95%] p-1 mt-1 bg-white border-2 border-gray-400 rounded shadow-2xl">
                  {filteredDeptData.map((dep) => (
                    <li key={dep._id} onClick={() => handleDeptSelect(dep)}
                      className="border-b-2 border-b-gray-300 p-2 hover:bg-blue-500 hover:text-white cursor-pointer">
                      {dep.departId} - {dep.departName}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="relative">
              <label>Designation</label>
              <input
                type="text"
                placeholder="Search Designation..."
                value={desigSearchTerm}
                onChange={(e) => setDesigSearchTerm(e.target.value)}
                readOnly={!!selectedDesig}
                className={errors.designation ? "errorInput" : ""}
              />
              {errors.designation && errorMessages()}
              <div className="absolute inset-y-0 my-auto mt-12 mx-6 right-2 flex items-center">
                {selectedDesig ? (
                  <RxCrossCircled
                    onClick={clearSelectedDesig}
                    className="cursor-pointer text-red-500 text-xl"
                  />
                ) : (
                  <button
                    type="button"
                    onClick={() => setShowDesDropdown((prev) => !prev)}
                    className='text-xs text-indigo-700 font-semibold cursor-pointer p-2 border-2 rounded-full border-indigo-700 hover:bg-black hover:text-white'
                  >
                    Select
                  </button>
                )}
              </div>

              {filteredDesigData.length > 0 && showDesDropdown && (
                <ul className="absolute z-50 min-w-[95%] p-1 mt-1 bg-white border-2 border-gray-400 rounded shadow-2xl">
                  {filteredDesigData.map((desig) => (
                    <li key={desig._id} onClick={() => handleDesigSelect(desig)}
                      className="border-b-2 border-b-gray-300 p-2 hover:bg-blue-500 hover:text-white cursor-pointer">
                      {desig.desigId} - {desig.desigName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className='InputBox'>
            <div>
              <label htmlFor="name">Salary</label>
              <input type="text" {...register("salary", { required: true })} className={errors.salary ? 'errorInput' : ""} />
              {errors.salary && errorMessages()}
            </div>


            <div>
              <label htmlFor="name">Any Refrence</label>
              <input type="text" {...register("anyRef", { required: true })} className={errors.anyRef ? 'errorInput' : ""} />
              {errors.anyRef && errorMessages()}
            </div>

          </div>

          <div className='InputBox'>

            <div>
              <label htmlFor="name">Phone Number</label>
              <input type="text" {...register("contact", { required: true })} className={errors.contact ? 'errorInput' : ""} />
              {errors.contact && errorMessages()}
            </div>

            <div>
              <label htmlFor="name">Address</label>
              <input type="text" {...register("address", { required: true })} className={errors.address ? 'errorInput' : ""} />
              {errors.address && errorMessages()}
            </div>
          </div>


          <div className='InputBox'>

            <div>
              <label htmlFor="name">Is Doctor/Consultant ?</label>
              <select
                {...register("isDoctor", { required: true })}
                className={errors.isDoctor ? 'errorInput' : ""}>
                <option value={false} >No</option>
                <option value={true}>Yes</option>
              </select>
              {errors.isDoctor && errorMessages()}
            </div>

            {watch("isDoctor") === "true" &&
              <div>
                <label htmlFor="name">specialization</label>
                <input type="text" {...register("specialization", { required: true })} className={errors.specialization ? 'errorInput' : ""} />
                {errors.specialization && errorMessages()}
              </div>}
          </div>

          <div className='InputBox'>

            {watch("isDoctor") === "true" &&
              <div>
                <label htmlFor="name">Consultancy Fee</label>
                <input type="text" {...register("consultancyFee", { required: true })} className={errors.consultancyFee ? 'errorInput' : ""} />
                {errors.consultancyFee && errorMessages()}
              </div>}
          </div>
          {/* Submit Button */}
          <div className="Button">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
