
import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useCompany() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCompanyExit, setIsCompanyExit] = useState()

const fetchProfile = async()=>{
  try {
      const {data,status} = await request("/company/getCompanyProfile","GET")
      if(status === 200){
        setIsCompanyExit(data)
      }
  } catch (error) {
      console.log(error)
  }
}

useEffect(()=>{
  fetchProfile()
},[])


    return { isLoading, isCompanyExit, error, fetchProfile };
}
