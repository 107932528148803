import React from "react";
import useAccount from "../../hooks/useAccount";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { request } from "../../util/fetchAPI";

export default function AddBanks() {
  const { isLoading, accountData = [], error, fetchAccountData } = useAccount();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      voucherId: null,
      voucherDate: null,
      voucherType: null,
      bankAccountIssuedTo: null,
      supplier: null,
      voucherSubType: null,
      paymentMethod: null,
      selectBank: null,
      accountNumber: null,
      availableBalance: null,
      amountIssued: null,
      description: null,
    },
  });

  const paymentMethod = watch("paymentMethod");
  const voucherGroup = watch("voucherType");

  const errorMessages = () => (
    <span className="flex items-center text-red-700 m-1">
      Required Field <BsExclamationCircle className="ml-1 text-lg" />
    </span>
  );

  const submitBankDetails = async (payload) => {
    console.log(payload)
 try {
      const { data, status } = await request(
        "/voucher/addVoucher",
        "POST",
        { "Content-Type": "application/json" },
        payload
      );

      if (status === 200 || status === 201) {
        reset(); // Reset form after successful submission
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred while submitting the bank details:", error.message);
    }
  };

  return (
    <div className="FormContainer p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <div className="flex justify-center Heading text-2xl m-auto font-bold">Add Voucher Details</div>

      <form onSubmit={handleSubmit(submitBankDetails)} className="space-y-4">
        {/* Voucher ID and Date */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="voucherId" className="block font-medium mb-1">
              Voucher ID
            </label>
            <input
              type="text"
              id="voucherId"
              {...register("voucherId", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${
                errors.voucherId ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.voucherId && errorMessages()}
          </div>
          <div>
            <label htmlFor="voucherDate" className="block font-medium mb-1">
              Voucher Date
            </label>
            <input
              type="date"
              id="voucherDate"
              {...register("voucherDate", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${
                errors.voucherDate ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.voucherDate && errorMessages()}
          </div>
        </div>

        {/* Voucher Group and Account */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="voucherType" className="block font-medium mb-1">
              Voucher Group
            </label>
            <select
              id="voucherType"
              {...register("voucherType", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${
                errors.voucherType ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value="">Select Voucher Group</option>
              <option value="payments">Payments</option>
              <option value="purchase">Purchase</option>
            </select>
            {errors.voucherType && errorMessages()}
          </div>
          <div>
            <label htmlFor="bankAccountIssuedTo" className="block font-medium mb-1">
              Select Account
            </label>
            <select
              id="bankAccountIssuedTo"
              {...register("bankAccountIssuedTo", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${
                errors.bankAccountIssuedTo ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value="">Select Account</option>
              {accountData.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.accountName}
                </option>
              ))}
            </select>
            {errors.bankAccountIssuedTo && errorMessages()}
          </div>
        </div>

        {/* Conditional Supplier or Voucher Type */}
        {voucherGroup === "purchase" ? (
          <div>
            <label htmlFor="supplier" className="block font-medium mb-1">
              Select Supplier
            </label>
            <select
              id="supplier"
              {...register("supplier", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${
                errors.supplier ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value="">Select Supplier</option>
              {/* Populate suppliers here */}
            </select>
            {errors.supplier && errorMessages()}
          </div>
        ) : (
          <div>
            <label htmlFor="voucherSubType" className="block font-medium mb-1">
              Voucher Type
            </label>
            <select
              id="voucherSubType"
              {...register("voucherSubType", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${
                errors.voucherSubType ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value="">Select Voucher Type</option>
              <option value="debit">Debit</option>
              <option value="credit">Credit</option>
            </select>
            {errors.voucherSubType && errorMessages()}
          </div>
        )}

        {/* Payment Method */}
        <div>
          <label htmlFor="paymentMethod" className="block font-medium mb-1">
            Payment Method
          </label>
          <select
            id="paymentMethod"
            {...register("paymentMethod", { required: true })}
            className={`w-full border rounded-lg px-3 py-2 ${
              errors.paymentMethod ? "border-red-500" : "border-gray-300"
            }`}
          >
            <option value="">Select Payment Method</option>
            <option value="Bank">Bank</option>
            <option value="Cash">Cash</option>
            <option value="Others">Others</option>
          </select>
          {errors.paymentMethod && errorMessages()}
        </div>

        {/* Bank Details */}
        {paymentMethod === "Bank" && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="selectBank" className="block font-medium mb-1">
                Select Bank
              </label>
              <input
                type="text"
                id="selectBank"
                {...register("selectBank", { required: true })}
                className={`w-full border rounded-lg px-3 py-2 ${
                  errors.selectBank ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.selectBank && errorMessages()}
            </div>
            <div>
              <label htmlFor="accountNumber" className="block font-medium mb-1">
                Account Number
              </label>
              <input
                type="text"
                id="accountNumber"
                {...register("accountNumber", { required: true })}
                className={`w-full border rounded-lg px-3 py-2 ${
                  errors.accountNumber ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.accountNumber && errorMessages()}
            </div>
          </div>
        )}

        {paymentMethod === "Bank" && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="availableBalance" className="block font-medium mb-1">
                Available Balance
              </label>
              <input
                type="text"
                id="availableBalance"
                {...register("availableBalance", { required: true })}
                className={`w-full border rounded-lg px-3 py-2 ${
                  errors.availableBalance ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.availableBalance && errorMessages()}
            </div>
            <div>
              <label htmlFor="amountIssued" className="block font-medium mb-1">
                Amount Issued
              </label>
              <input
                type="text"
                id="amountIssued"
                {...register("amountIssued", { required: true })}
                className={`w-full border rounded-lg px-3 py-2 ${
                  errors.amountIssued ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.amountIssued && errorMessages()}
            </div>
          </div>
        )}

        {/* Description */}
        <div>
          <label htmlFor="description" className="block font-medium mb-1">
            Description
          </label>
          <textarea
            id="description"
            {...register("description")}
            className="w-full border rounded-lg px-3 py-2"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Submit Voucher
        </button>
      </form>
    </div>
  );
}
