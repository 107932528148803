import React, { useState, useEffect } from 'react';
import useDeleteData from '../../hooks/useDeleteData';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdEditDocument, MdAddCircle, MdPrint } from "react-icons/md";
import { request } from '../../util/fetchAPI';
import "./UserList.css";

export default function UserList() {
  const { isLoading: isDeleteLoading, error: deleteError, isDelete, message, setIsDeleted, deleteData } = useDeleteData();
  const { isLoading, userData, error, refetch } = useUser();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Effect to filter employees based on the search term
  useEffect(() => {
    setFilteredData(userData);
  const filterData = userData && userData?.filter((user) =>
      user.userId.toLowerCase().includes(searchTerm.toLowerCase())
    );
     setFilteredData(filterData);
  }, [searchTerm, userData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = (id) => {
    deleteData('user', 'deleteUser', id);
};

useEffect(() => {
    if (isDelete) {
        successNotifier(message);
        refetch();
        setIsDeleted(false);
    }
}, [isDelete, refetch, message]);


  return (
    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
      <ToastContainer />
      <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>User List</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button
                type="button"
                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                onClick={() => navigate("/employeeform")}
              >
                <MdAddCircle className='mr-2 text-xl' />
                Add New
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            {isLoading ? (
              <div className='loaderContainer'>
                <span className="loader"></span>
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">#</th>
                    <th scope="col" className="px-4 py-3">User Id</th>
                    <th scope="col" className="px-4 py-3">Is Employee</th>
                    <th scope="col" className="px-4 py-3">Modules</th>
                    <th scope="col" className="px-4 py-3">Status</th>
                    <th scope="col" className="sr-only text-black"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData?.map((data, i) => (
                    <tr key={data._id} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3">{++i}</td>
                      <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.userId}</th>
                      <td className="px-4 py-3">{data.isEmployee ? "YES" : "NO"}</td>
                      <td className="px-4 py-3">{data.moduleName}</td>
                      <td className="px-4 py-3">{data.isActive ? "ACTIVE" : "IN-Active"}</td>
                      <td className="px-4 py-3 flex items-center justify-end gap-2 cursor-pointer">
                        <FaEye className='text-xl' />
                        <MdDelete className='text-xl' onClick={() => handleDelete(data._id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
