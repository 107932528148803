import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FaSearch, FaEye } from "react-icons/fa";
import {MdAddCircle, } from "react-icons/md";

export default function TransectionTable({ TransDtl }) {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [receiptTypeFilter, setReceiptTypeFilter] = useState('all');
  const navigate = useNavigate();

  // Effect to filter receipts based on the search term and receiptType filter
  useEffect(() => {
    let filtered = TransDtl;

    // Filter by receiptNo (search term)
    if (searchTerm) {
      filtered = filtered.filter((data) =>
        data?.receipt?.receiptNo?.toString().includes(searchTerm)
      );
    }

    // Filter by receiptType (debit, credit, or all)
    if (receiptTypeFilter !== 'all') {
      filtered = filtered.filter((data) => data?.receipt?.receiptType === receiptTypeFilter);
    }

    setFilteredData(filtered);
  }, [searchTerm, receiptTypeFilter, TransDtl]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReceiptTypeChange = (event) => {
    setReceiptTypeFilter(event.target.value);
  };

  return (
    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
      <ToastContainer />
      <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight">Transection List</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search by Receipt No"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>

            {/* Receipt Type Filter */}
            <div className="w-full md:w-1/3">
              <select
                value={receiptTypeFilter}
                onChange={handleReceiptTypeChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="all">All</option>
                <option value="debit">Debit</option>
                <option value="credit">Credit</option>
              </select>
            </div>

            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button
                type="button"
                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                onClick={() => navigate("/patientform")}
              >
                <MdAddCircle className="mr-2 text-xl" />
                Add New
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">#</th>
                  <th scope="col" className="px-4 py-3">Receipt No</th>
                  <th scope="col" className="px-4 py-3">Discount</th>
                  <th scope="col" className="px-4 py-3">Amount</th>
                  <th scope="col" className="px-4 py-3">Receipt Type</th>
                  <th scope="col" className="px-4 py-3">Source</th>
                  <th scope="col" className="px-4 py-3">Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData?.map((data, i) => (
                    <tr key={data._id} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3">{i + 1}</td>
                      <td className="px-4 py-3">{data.receipt?.receiptNo}</td>
                      <td className="px-4 py-3">{data.receipt?.discount}%</td>
                      <td className={data.receipt?.receiptType === "debit" ? "px-4 py-3 text-green-800 font-bold" : "px-4 py-3" }>{data.receipt?.amount}.00</td>
                      <td className={data.receiptType === "debit" ? "px-4 py-3 text-green-800 font-bold" : "px-4 py-3 text-red-800 font-bold" }>{data.receipt?.receiptType}</td>
                      <td className="px-4 py-3">{data.receipt?.receiptSource}</td>
                      <td className="px-4 py-3">{data.receipt?.date}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-4 py-3 text-center">
                      No receipts found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
