import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useDeleteData from '../../hooks/useDeleteData';
import { BsExclamationCircle } from "react-icons/bs";
import { request } from '../../util/fetchAPI';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useNavigate } from 'react-router-dom';
import useAccount from '../../hooks/useAccount';
import useBankList from '../../hooks/useBankList'; // Importing the useBankList hook
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdEditDocument, MdAddCircle, MdPrint } from "react-icons/md";

export default function AccountForm() {
    const { register, handleSubmit,watch,reset, formState: { errors } } = useForm({
        defaultValues: {
            bankId: null, // Set default value for bankId
        },
    });
    const { isLoading: isDeleteLoading, error: deleteError, isDelete, message, setIsDeleted, deleteData } = useDeleteData();
    const { isLoading, accountData, error, fetchAccountData } = useAccount();
    const { isLoading: isBankLoading, bankData, error: bankError, fetchBankData } = useBankList(); // Use the hook for bank data
    const [filteredDepData, setFilteredDepData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    // Effect to filter employees based on the search term
    useEffect(() => {
        setFilteredDepData(accountData);
        const filterData = accountData && accountData?.filter((account) =>
            account.accountId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            account.accountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDepData(filterData);
    }, [searchTerm, accountData]);


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const errorMessages = () => {
        return (<span className='flex items-center justify-start text-red-700 m-1'>Required Field <BsExclamationCircle className='m-1 text-lg' /></span>);
    };

    const accountFormHandler = async (payload) => {
        const {data, status} = await request('/account/addAccount', 'POST', {
            'Content-Type': 'application/json'
        }, payload);
        if(status === 200){
            reset()
            successNotifier(data.message)
            fetchAccountData();
        }else{
            errorNotifier(data.message)
        }
    };

    const handleDelete = (id) => {
        deleteData('account', 'deleteAccount', id);
    };

    useEffect(() => {
        if (isDelete) {
            successNotifier(message);
            fetchAccountData();
            setIsDeleted(false);
        }
    }, [isDelete, fetchAccountData, message]);


    return (
        <div className='flex flex-col justify-center w-full'>
                       <ToastContainer />
            <div className='Heading'>
                Add New Account
            </div>

            <div className='flex justify-center w-[100%] p-3'>
                <form onSubmit={handleSubmit(accountFormHandler)} className='forms w-[100%] min-h-[40vh]'>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="accountId" className="block mb-1">Account Id</label>
                            <input
                                type="text"
                                {...register("accountId", { required: true })}
                                className={errors.accountId ? 'errorInput' : ""}
                            />
                            {errors.accountId && errorMessages()}
                        </div>
                        <div>
                            <label htmlFor="accountName" className="block mb-1">Account Name</label>
                            <input
                                type="text"
                                {...register("accountName", { required: true })}
                                className={errors.accountName ? 'errorInput' : ""}
                            />
                            {errors.accountName && errorMessages()}
                        </div>
                    </div>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="accountType" className="block mb-1">Account Type</label>
                            <select
                                {...register("accountType", { required: true })}
                                className={errors.accountType ? 'errorInput' : ""}
                            >
                                <option value="" disabled>Select Account Type</option>
                                <option value="expense">Expense</option>
                                <option value="income">Income</option>
                                <option value="others">Others</option>
                            </select>
                            {errors.accountType && errorMessages()}
                        </div>

                        <div>
                            <label htmlFor="remarks" className="block mb-1">Remarks</label>
                            <input
                                type="text"
                                {...register("remarks",)}
                                className={errors.remarks ? 'errorInput' : ""}
                            />
                            {errors.remarks && errorMessages()}
                        </div>
                    </div>


                    {/* Link Bank Account Dropdown */}
                    {watch("accountType") !== "others" &&

                        <div>
                        <div className='InputBox'>
                        <div>
                            <label htmlFor="bankId" className="block mb-1">Link Bank Account</label>
                            <select
                                {...register("bankId")}
                                className={errors.bankId ? 'errorInput' : ""}
                            >
                                <option value="" selected disabled>Select Bank</option>
                                {isBankLoading ? (
                                    <option>Loading...</option>
                                ) : (
                                    bankData?.map((bank) => (
                                        <option key={bank._id} value={bank._id}>
                                            {bank.bankName} - {bank.accountNo}
                                        </option>
                                    ))
                                )}
                            </select>
                            {errors.bankAccount && errorMessages()}
                        </div>
                        </div>
                           <span className='text-red-700'>For auto increment & deduction if <strong>**Account Type**</strong> is expense or income</span>
                        </div>
                    }

                    <div className='Button'>
                        <button>
                            Submit
                        </button>
                    </div>
                </form>
            </div>

            <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5">

                <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Account List</h1>
                <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <div className="flex items-center">
                                    <label className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <FaSearch />
                                        </div>
                                        <input
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Search"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                                <button
                                    type="button"
                                    className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                    onClick={() => navigate("/employeeform")}
                                >
                                    <MdAddCircle className='mr-2 text-xl' />
                                    Add New
                                </button>
                            </div>
                        </div>
                        <div className="overflow-x-auto">
                            {isLoading ? (
                                <div className='loaderContainer'>
                                    <span className="loader"></span>
                                </div>
                            ) : (
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-4 py-3">#</th>
                                            <th scope="col" className="px-4 py-3">Account Id</th>
                                            <th scope="col" className="px-4 py-3">Account Name</th>
                                            <th scope="col" className="px-4 py-3">Type</th>
                                            <th scope="col" className="px-4 py-3">Bank</th>
                                            <th scope="col" className="px-4 py-3">Remarks</th>
                                            <th scope="col" className="px-4 py-3">Status</th>
                                            <th scope="col" className="sr-only"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredDepData && filteredDepData.length ? filteredDepData.map((account, index) => (
                                            <tr key={account._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <td className="px-4 py-3">{index + 1}</td>
                                                <td className="px-4 py-3">{account.accountId}</td>
                                                <td className="px-4 py-3">{account.accountName}</td>
                                                <td className="px-4 py-3">{account.accountType}</td>
                                                <td className="px-4 py-3">{account.bankId?.bankName} - {account.bankId?.accountNo}</td>
                                                <td className="px-4 py-3">{account.remarks}</td>
                                                <td className="px-4 py-3">{account.isActive ? 'Active' : 'Inactive'}</td>
                                                <td className="px-4 py-3 flex justify-end items-center space-x-4">
                                                    <button className="text-blue-600 hover:text-blue-900">
                                                    <FaEye className='text-xl' onClick={()=>navigate(`/accountView/${account._id}`)}/>
                                                    </button>
                                                    <button className="text-red-600 hover:text-red-900">
                                                    <MdDelete className='text-xl' onClick={() => handleDelete(account._id)} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="7" className="text-center py-3">No Data Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
