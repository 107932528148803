import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useVerfyReceipt() {
    const [isLoading, setIsLoading] = useState(true);
    const [receiptData, setReceiptData] = useState([]);
    const [error, setError] = useState(null);

    const verifyReceipts = async (id, bankId) => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/receipt/varifyReceipt/${id}`, "PUT", {"Content-Type": "application/json"}, {bankId});
            setReceiptData(data)
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };


    return { isLoading, receiptData, error, verifyReceipts };
}
